a {
  color: #000; }
  a:hover {
    color: #000; }

.header_top a {
  color: #fff; }
  .header_top a:hover {
    text-decoration: none;
    color: #fff; }

.logo {
  margin: 0; }
  .logo img {
    height: 100px; }

li.cesta-home {
  margin-bottom: 1em !important; }

.arriv {
  margin: 1em; }
  .arriv p {
    color: #000; }

body > div.header_bg > div > div > div > div.header_right {
  width: 50% !important; }

.btn {
  border-radius: 0; }
  .btn.btn-success {
    background-color: #000;
    border-color: #000;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s; }
    .btn.btn-success:hover {
      background-color: #FEE401;
      color: #000; }
  .btn.btn-primary:hover {
    background-color: #242424; }

.cesta h1 {
  color: #333333;
  font-size: 1.4em;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0 0 0.5em;
  font-family: 'Playfair Display', serif; }

.cesta .brand {
  text-transform: capitalize; }

.cesta span.price-new {
  color: #afa54e; }

.cesta .btn_form a, .cesta .button1 {
  background: #afa54e; }

.cesta button.btn_form {
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  border: none;
  outline: none;
  display: inline-block;
  font-size: 1em;
  padding: 10px 34px;
  background: #afa54e;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: color 0.5s;
  transition: color 0.5s; }
  .cesta button.btn_form:hover {
    color: #000; }

.cart-total h3 {
  color: #000; }

.check .calcular-frete a {
  font-size: 0.9em; }

#checkout .box.shipping-method {
  border: 1px solid #949494;
  padding: 22px;
  border-radius: 5px;
  margin-bottom: 22px;
  cursor: pointer; }
  #checkout .box.shipping-method.selected {
    background-color: #fee401; }

#checkout ul.nav {
  background: #f3f4f5;
  margin-bottom: 5px; }
  #checkout ul.nav li {
    margin-bottom: 5px;
    border-radius: 5px; }
    #checkout ul.nav li.active a {
      color: #000;
      background-color: #fee401; }

.arriv-left {
  margin: 10px 0; }
  .arriv-left .arriv-info {
    position: absolute;
    bottom: 0;
    top: initial;
    left: 0;
    margin-top: -4em;
    margin-left: 13px; }
    .arriv-left .arriv-info a {
      text-decoration: none; }
      .arriv-left .arriv-info a h3 {
        background-color: rgba(60, 60, 60, 0.87);
        padding: 10px;
        border-radius: 5px; }

.client-menu {
  list-style: none;
  padding: 0; }
  .client-menu li a {
    margin-bottom: 5px;
    font-size: 1em; }
  .client-menu .glyphicon {
    margin-top: 5px; }

.stay form input[type="email"] {
  width: 78%;
  color: #3f3d3d;
  outline: none;
  font-size: 1em;
  padding: 0.5em;
  font-weight: 300;
  border: 1px solid #e5e4e2;
  -webkit-appearance: none; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background-color: #fff; }

#bg-y {
  background-color: #fee401; }

img.img-banner {
  width: 100%; }

i.wpp {
  width: 20px;
  height: 24px;
  display: inline-block;
  background: url(../images/img-sprite.png) no-repeat -268px -11px;
  vertical-align: middle;
  margin-right: 0.5em; }

.top_left h2 span.wpp {
  color: #000000;
  margin-right: 0px;
  padding: 1px 10px;
  background: url(../images/wpp.png) no-repeat 0px 2px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/*# sourceMappingURL=sass-front-compiled.css.map */
